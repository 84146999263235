<template>
  <div id="render-form">
    <Tabs
      v-if="formSettings.general.layout === 'TAB'"
      :value="activePanel"
      :tabs="_tabs"
      class="q-mb-lg"
      @input="changePanelTab"
    />

    <BaseScrollbar :style="height ? 'max-height:' + height : ''">
      <!-- panels -->

      <ValidationObserver ref="form">
        <template v-for="panel in _panels">
          <RenderPanel
            v-if="checkVisibility(panel.id)"
            :key="panel.id"
            :form-id="formId"
            :title="panel.settings.title"
            :description="panel.settings.description"
            :fields="panel.fields"
            :form-controls="formControls"
            :fields-model="_fieldsModel"
            :secondary-panels="secondaryPanels"
            :form-settings="formSettings"
            :no-padding="noPadding"
            :is-readonly="isReadonly"
            :form-edit-controls="formEditControls"
            :readonly-controls="readonlyControls"
            :form-visibility-access="formVisibilityAccess"
            :form-secure-controls="formSecureControls"
            :edit-panel-controls="checkEditControls(panel.id)"
            :enable-controls="enableControls"
            :visible-controls.sync="visibleControls"
            :data-validation-values="dataValidationValues"
            :form-upload-files="formUploadFiles"
            :ocr-result="ocrResult"
            :external-link="externalLink"
            :portal-id="portalId"
            :form-view="formSettings.general.layout"
            class="q-mb-md"
            @mandatoryControls="setMandatoryControls"
            @uploadFiles="uploadFiles"
            @deletedFiles="deletedFiles"
            @setReadonlyControls="setReadonlyControls"
            @formSaveType="formSaveType"
            @loading="externalLoading"
            @showQr="showQr"
          >
          </RenderPanel>
        </template>
      </ValidationObserver>

      <!-- ... -->
    </BaseScrollbar>

    <template v-if="!isReadonly || formSettings.general.layout === 'CARD'">
      <!-- card layout's actions -->

      <div
        v-if="formSettings.general.layout === 'CARD'"
        class="actions"
        :style="workflowFormModel ? ' justify-content: center' : ''"
      >
        <BaseButton
          v-if="activePanel !== 0"
          label="previous"
          is-flat
          class="q-mr-sm"
          @click="previous"
        />

        <BaseButton
          v-if="activePanel !== panelCount - 1 && checkNextAction"
          label="next"
          class="q-mr-md"
          @click="next"
        />
        <template
          v-if="
            activePanel === panelCount - 1 &&
            formSettings.general.type !== 'WORKFLOW'
          "
        >
          <BaseButton label="save" @click="save" />
        </template>
        <template
          v-else-if="
            activePanel === panelCount - 1 &&
            formSettings.general.type === 'WORKFLOW'
          "
        >
          <template v-for="action in workflowActions">
            <BaseButton
              :key="action"
              :label="action"
              @click="processSave(action)"
            />
          </template>
        </template>
      </div>

      <!-- ... -->

      <!-- actions -->
      <div v-else>
        <template v-if="formSettings.general.type === 'WORKFLOW'">
          <div v-if="workflowActions.length" class="actions">
            <template v-for="action in workflowActions">
              <BaseButton
                :key="action"
                :label="action"
                @click="processSave(action)"
              />
            </template>
          </div>
        </template>
        <template v-else-if="!isPreview">
          <div class="actions">
            <BaseButton label="save" @click="save" />
          </div>
        </template>
      </div>

      <!-- ... -->
    </template>
  </div>
</template>

<script>
import Tabs from "@/components/common/tabs/Tabs2.vue";
import { ValidationObserver } from "vee-validate";
import FieldsModelMixin from "./mixins/fields-model.js";
import { has, merge } from "lodash-es";
import { evaluateFormula } from "@/helpers/formula.js";
// import { cloneDeep } from "lodash-es";

export default {
  name: "RenderForm",

  components: {
    Tabs,
    ValidationObserver,
  },

  mixins: [FieldsModelMixin],

  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "0",
    },

    formSettings: {
      type: Object,
      required: true,
    },

    panels: {
      type: Array,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    formModel: {
      type: Object,
      default: () => {},
    },

    noPadding: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    saveAction: {
      type: String,
      default: "",
    },

    workflowFormModel: {
      type: Object,
      default: () => {},
    },

    workflowActions: {
      type: Array,
      default: () => [],
    },

    formEditControls: {
      type: Array,
      default: () => [],
    },

    readonlyControls: {
      type: Array,
      default: () => [],
    },

    formVisibilityAccess: {
      type: Boolean,
      default: true,
    },

    formSecureControls: {
      type: Array,
      default: () => [],
    },

    enableControls: {
      type: Object,
      default: () => {},
    },

    checkList: {
      type: Array,
      default: () => [],
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    dataValidationValues: {
      type: Array,
      default: () => [],
    },

    formCheckList: {
      type: Boolean,
      default: false,
    },

    formUploadFiles: {
      type: Array,
      default: () => [],
    },

    hasCheckFormValidate: {
      type: Boolean,
      default: false,
    },

    ocrResult: {
      type: Array,
      default: () => [],
    },

    height: {
      type: String,
      default: "",
    },

    externalLink: {
      type: Boolean,
      default: false,
    },

    portalId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      activePanel: 0,
      fieldsModel: {},
      calculatedFieldsModel: {},
      visibleControls: [],
      formControls: [],
      mandatoryControls: [],
      mandatoryControlsList: {},
      readonlyControlsList: [],
      checkNextAction: true,
    };
  },

  computed: {
    _tabs() {
      let tabList = [];
      this.panels.forEach((panel, idx) => {
        if (this.formSecureControls.length) {
          if (typeof this.formSecureControls[0] === "object") {
            let hasEmail = this.formSecureControls.find(
              (item) => item.userId === this.$store.state.session.id
            );
            if (hasEmail && hasEmail.formFields.length) {
              if (!hasEmail.formFields.includes(panel.id)) {
                const label = panel.settings.title || `Panel ${idx + 1}`;

                tabList.push({
                  id: this.$nano.id(),
                  label: label,
                  value: idx,
                });
                if (tabList.length === 1) {
                  this.activePanel = tabList[0].value;
                }
              }
            } else {
              const label = panel.settings.title || `Panel ${idx + 1}`;

              tabList.push({
                id: this.$nano.id(),
                label: label,
                value: idx,
              });
              if (tabList.length === 1) {
                this.activePanel = tabList[0].value;
              }
            }
          } else {
            if (!this.formSecureControls.includes(panel.id)) {
              const label = panel.settings.title || `Panel ${idx + 1}`;

              tabList.push({
                id: this.$nano.id(),
                label: label,
                value: idx,
              });
              if (tabList.length === 1) {
                this.activePanel = tabList[0].value;
              }
            }
          }
        } else {
          const label = panel.settings.title || `Panel ${idx + 1}`;

          tabList.push({
            id: this.$nano.id(),
            label: label,
            value: idx,
          });
          if (tabList.length === 1) {
            this.activePanel = tabList[0].value;
          }
        }
      });

      return tabList;
    },

    _panels() {
      if (this.formSettings.general.layout === "CLASSIC") {
        return this.panels;
      }
      return [this.panels[this.activePanel]];
    },

    _fieldsModel() {
      return merge(this.fieldsModel, this.calculatedFieldsModel);
    },

    _formModel() {
      let model = {};
      if (this.formModel) {
        if (Object.keys(this.formModel).length) {
          if (!this.panels.length) {
            return;
          }

          this.panels.forEach((panel) => {
            if (!panel.fields.length) {
              return;
            }

            // for (let field of panel.fields) {
            //   model[field.id] = this.isArrayField(field.type)
            //     ? JSON.parse(this.formModel[field.id])
            //     : this.formModel[field.id];
            // }
            for (let field of panel.fields) {
              if (!this.isStatelessField(field.type)) {
                if (this.isArrayField(field.type)) {
                  if (this.formModel[field.id]) {
                    try {
                      model[field.id] = JSON.parse(this.formModel[field.id]);
                    } catch (e) {
                      model[field.id] = this.formModel[field.id];
                    }
                  } else {
                    model[field.id] = [];
                  }
                } else if (
                  field.type === "COUNTER" ||
                  field.type === "CALCULATED"
                ) {
                  model[field.id] = Number(this.formModel[field.id]);
                } else {
                  model[field.id] = this.formModel[field.id] || "";
                }
                // model[field.id] = this.isArrayField(field.type)
                //   ? this.formModel[field.id].length
                //     ? JSON.parse(this.formModel[field.id])
                //     : []
                //   : this.formModel[field.id];
                if (this.enableControls) {
                  if (Object.keys(this.enableControls).length) {
                    if (this.enableControls.controls.length) {
                      let index = this.enableControls.controls.findIndex(
                        (row) => row.id === field.id
                      );
                      if (index > -1) {
                        this.enableControls.controls[
                          index
                        ].parentControls.forEach((control) => {
                          if (control.value === this.formModel[field.id]) {
                            if (control.childControls.length) {
                              this.visibleControls.push(
                                ...control.childControls
                              );
                            }
                          }
                        });
                      }
                    }
                  }
                }
              }
            }
          });

          let _model = { ...this.formModel, ...model };

          return _model;
        }
      }

      return model;
    },

    panelCount() {
      let panelsCount = 0;
      this.panels.forEach((panel) => {
        // if (!this.formSecureControls.includes(panel.id)) {
        //   panelsCount += 1;
        // }
        if (this.formSecureControls.length) {
          if (typeof this.formSecureControls[0] === "object") {
            let hasEmail = this.formSecureControls.find(
              (item) => item.userId === this.$store.state.session.id
            );
            if (hasEmail && hasEmail.formFields.length) {
              if (!hasEmail.formFields.includes(panel.id)) {
                panelsCount += 1;
              }
            }
          } else {
            if (!this.formSecureControls.includes(panel.id)) {
              panelsCount += 1;
            }
          }
        } else {
          panelsCount += 1;
        }
      });
      return panelsCount;
    },
  },

  watch: {
    fieldsModel: {
      immediate: true,
      deep: true,
      handler() {
        this.formControls = [];
        const rules = this.formSettings.rules;
        this.panels.forEach((panel) => {
          this.formControls.push(...panel.fields);
        });
        let filledValue = 0;
        let nonFilledValue = 0;
        this.formControls.forEach((control) => {
          if (this.checkVisibility(control.id)) {
            if (
              control.settings.validation.fieldRule === "REQUIRED" &&
              control.settings.general.visibility === "NORMAL"
            ) {
              if (control.settings.validation.answerIndicator === "YES") {
                if (Array.isArray(this._fieldsModel[control.id])) {
                  if (this._fieldsModel[control.id].length) {
                    filledValue += 1;
                  } else {
                    nonFilledValue += 1;
                  }
                } else if (this._fieldsModel[control.id] === "<p></p>") {
                  if (this.removeTags(this._fieldsModel[control.id])) {
                    filledValue += 1;
                  } else {
                    nonFilledValue += 1;
                  }
                } else if (this._fieldsModel[control.id]) {
                  filledValue += 1;
                } else {
                  nonFilledValue += 1;
                }
              }
            }
          }
        });
        this.mandatoryControlsList = {
          filledValue: filledValue,
          nonFilledValue: nonFilledValue,
        };
        this.$emit("mandatoryControlsList", this.mandatoryControlsList);
        for (const rule of rules) {
          let proceed = true;
          if (rule.isConditionalRule) {
            proceed = false;
            for (const condition of rule.conditions) {
              switch (condition.logic) {
                case "IS_EQUALS_TO (=)":
                  if (this.fieldsModel[condition.name] === condition.value) {
                    proceed = true;
                  }
                  break;
              }
            }
          }
          if (proceed) {
            let fields = [];
            this.panels.forEach((panel) => {
              if (!panel.fields.length) {
                return;
              }
              for (let field of panel.fields) {
                fields.push(field);
              }
            });
            const calculations = rule.calculations;
            for (const calculation of calculations) {
              if (has(this.calculatedFieldsModel, calculation.fieldId)) {
                let calcValue = evaluateFormula(
                  calculation.formula,
                  this.fieldsModel,
                  fields
                );
                //if (calcValue) {
                if (typeof calcValue === "number") {
                  this.calculatedFieldsModel[calculation.fieldId] =
                    calcValue === 0 ? 0 : parseFloat(calcValue.toFixed(2));
                }
                //}
                // else {
                //   this.calculatedFieldsModel[calculation.fieldId] = 0
                // }
              }
            }
          }
        }
        if (this.enableControls) {
          if (this.enableControls.listControls.length) {
            this.checkNextAction = true;
            let currentPanel = false;
            let proceedPanel = true;
            this.panels.forEach((panel) => {
              if (currentPanel && proceedPanel) {
                if (this.enableControls.listControls.indexOf(panel.id) > -1) {
                  this.checkNextAction = false;
                  if (this.visibleControls.indexOf(panel.id) > -1) {
                    console.log(panel.id);
                    this.checkNextAction = true;
                    proceedPanel = false;
                  }
                } else {
                  this.checkNextAction = true;
                  proceedPanel = false;
                }
              }
              if (panel.id === this.panels[this.activePanel].id) {
                currentPanel = true;
              }
            });
          }
        }
      },
    },

    activePanel: {
      immediate: true,
      deep: true,
      handler() {
        if (this.enableControls) {
          if (this.enableControls.listControls.length) {
            this.checkNextAction = true;
            let currentPanel = false;
            let proceedPanel = true;
            this.panels.forEach((panel) => {
              if (currentPanel && proceedPanel) {
                if (this.enableControls.listControls.indexOf(panel.id) > -1) {
                  this.checkNextAction = false;
                  if (this.visibleControls.indexOf(panel.id) > -1) {
                    console.log(panel.id);
                    this.checkNextAction = true;
                    proceedPanel = false;
                  }
                } else {
                  this.checkNextAction = true;
                  proceedPanel = false;
                }
              }
              if (panel.id === this.panels[this.activePanel].id) {
                currentPanel = true;
              }
            });
          }
        }
      },
    },

    saveAction: {
      immediate: true,
      async handler() {
        if (this.saveAction) {
          if (
            this.saveAction === "Save" ||
            this.saveAction === "Force Close" ||
            this.saveAction === "Reopen"
          ) {
            this.$emit("update:workflowFormModel", this._fieldsModel);
            return;
          }

          var nonFilledValue = [];
          var nonFilledId = [];

          this.formFields.forEach((field) => {
            if (this.checkVisibility(field.value)) {
              if (field.settings.validation.fieldRule === "REQUIRED") {
                if (!this._fieldsModel[field.value]) {
                  nonFilledValue.push(field.label);
                  nonFilledId.push(field.value);
                }
              }
            }
          });

          // console.log(nonFilledValue, nonFilledId);
          let validation = true;

          nonFilledId.forEach((ids) => {
            if (validation) {
              this.panels.forEach((panel, index) => {
                let fields = panel.fields.findIndex((field) => {
                  return field.id === ids;
                });

                if (fields >= 0) {
                  validation = false;

                  this.activePanel = index;
                }
              });
            }
          });

          if (!nonFilledValue.length) {
            const isValid = await this.$refs.form.validate();
            if (!isValid) {
              let errorList = [];
              this.formControls.forEach((field) => {
                if (
                  this.$refs.form.errors[field.label] &&
                  this.$refs.form.errors[field.label].length
                ) {
                  errorList.push(field.label);
                }
              });
              this.$alert.warning("Required Mandatory Info", errorList);

              this.$emit("update:saveAction", "");
              this.$emit("update:workflowFormModel", {});
              return;
            } else {
              // if (this.panelCount > this.activePanel) {
              //   this.activePanel = this.activePanel + 1;
              //   const isValid1 = await this.$refs.form.validate();
              //   console.log(this.$refs.form, isValid1);
              //   if (!isValid1) {
              //     this.$emit("update:saveAction", "");
              //     this.$emit("update:workflowFormModel", {});
              //   }
              // }
            }
            this.$emit("update:workflowFormModel", this._fieldsModel);
          } else {
            const isValid = await this.$refs.form.validate();

            const isValid1 = await this.$refs.form.validate();

            this.$alert.warning("Required Mandatory Info", nonFilledValue);

            this.$emit("update:saveAction", "");
            this.$emit("update:workflowFormModel", {});
            if (!isValid || !isValid1) {
              return;
            }
          }
        }
      },
    },

    _fieldsModel: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("update:workflowModel", this._fieldsModel);
        if (this.checkList.length) {
          this.checkDocument();
          let origin = location.origin;
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net" ||
            (origin === "https://trial.ezofis.com" &&
              this.$store.state.session.tenantId === 12)
          ) {
            this.documentValidation();
          }
        }
      },
    },

    formCheckList: {
      immediate: true,
      deep: true,
      handler() {
        if (this.formCheckList) {
          let origin = location.origin;
          if (
            (origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net" ||
              (origin === "https://trial.ezofis.com" &&
                this.$store.state.session.tenantId === 12)) &&
            this.checkList.length
          ) {
            this.documentValidation();
            this.$emit("update:formCheckList", false);
          }
        }
      },
    },

    hasCheckFormValidate: {
      deep: true,
      async handler() {
        if (this.hasCheckFormValidate) {
          const isValid = await this.$refs.form.validate();

          let errorList = [];
          this.formControls.forEach((field) => {
            if (
              this.$refs.form.errors[field.label] &&
              this.$refs.form.errors[field.label].length
            ) {
              errorList.push(field.label);
            }
          });
          this.$alert.warning("Required Mandatory Info", errorList);
          this.$emit("update:hasCheckFormValidate", false);
          if (!isValid) {
            return;
          }
        }
      },
    },
  },

  created() {
    this.fieldsModel = merge(this.fieldsModel, this._formModel);
  },

  methods: {
    checkVisibility(fieldId) {
      if (this.enableControls) {
        let index = this.enableControls.listControls.findIndex(
          (row) => row === fieldId
        );
        let showControlIndex = this.visibleControls.findIndex(
          (row) => row === fieldId
        );
        if (index > -1 && showControlIndex < 0) {
          return false;
        }
      } else if (this.formVisibilityAccess) {
        return true;
      } else if (this.formSecureControls.length === 0) {
        return true;
      }

      if (typeof this.formSecureControls[0] === "object") {
        let hasEmail = this.formSecureControls.find(
          (item) => item.userId === this.$store.state.session.id
        );
        if (hasEmail && hasEmail.formFields.length) {
          return !hasEmail.formFields.includes(fieldId);
        } else {
          return true;
        }
      } else {
        return !this.formSecureControls.includes(fieldId);
      }

      //return !this.formSecureControls.includes(fieldId);
    },

    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    checkEditControls(fieldId) {
      if (this.isReadonly) {
        return this.isReadonly;
      } else if (this.formEditControls.length === 0) {
        return false;
      }
      // console.log(fieldId, typeof this.formEditControls[0]);
      if (
        typeof this.formEditControls === "object" ||
        typeof this.formEditControls[0] === "string"
      ) {
        // console.log(this.formEditControls);
        let formEditControls = [];
        let singleEditControl = [];
        if (typeof this.formEditControls[0] === "object") {
          formEditControls = this.formEditControls;
        } else {
          this.formEditControls.forEach((edit) => {
            if (typeof edit === "string") {
              if (this.isJsonString(edit)) {
                formEditControls.push(JSON.parse(edit));
              } else {
                singleEditControl.push(edit);
              }
            }
          });
        }
        if (formEditControls.length) {
          // console.log(formEditControls);
          let hasEmail = formEditControls.find(
            (item) => item.userId === this.$store.state.session.id
          );
          // console.log(fieldId);
          // console.log(hasEmail, hasEmail.formFields);
          if (hasEmail && hasEmail.formFields.length) {
            // console.log(hasEmail.formFields.includes(fieldId));
            let formfields = [];
            hasEmail.formFields.forEach((form) => {
              let table = form.split("@");
              // console.log(table);
              if (table.length === 2) {
                // console.log(table[0].trim(), fieldId);
                if (table[0].trim() === fieldId) {
                  // return true;
                  formfields.push(table[0].trim());
                }
              } else {
                if (form === fieldId) {
                  // return true;
                  formfields.push(form);
                } else {
                  // return false;
                }
              }
            });
            // console.log(formfields);
            if (formfields.length) {
              return formfields.includes(fieldId);
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else if (singleEditControl.length) {
          // console.log(singleEditControl);
          return singleEditControl.includes(fieldId);
        }
      } else {
        return this.formEditControls.includes(fieldId);
      }
      //return this.formEditControls.includes(fieldId);
    },

    async previous() {
      this.activePanel -= 1;
      this.panels
        .slice()
        .reverse()
        .forEach((panel) => {
          if (this.panels[this.activePanel].id === panel.id) {
            if (this.formSecureControls.includes(panel.id)) {
              this.activePanel -= 1;
            } else if (this.enableControls) {
              if (this.enableControls.listControls.includes(panel.id)) {
                if (!this.visibleControls.includes(panel.id)) {
                  this.activePanel -= 1;
                }
              }
            }
          }
        });
    },

    async next() {
      if (!this.isPreview) {
        const isValid = await this.$refs.form.validate();

        if (!isValid) {
          let errorList = [];
          this.formControls.forEach((field) => {
            if (
              this.$refs.form.errors[field.label] &&
              this.$refs.form.errors[field.label].length
            ) {
              errorList.push(field.label);
            }
          });
          this.$alert.warning("Required Mandatory Info", errorList);

          return;
        }
      }
      this.activePanel += 1;
      this.panels.forEach((panel) => {
        if (this.panels[this.activePanel].id === panel.id) {
          if (this.formSecureControls.includes(panel.id)) {
            this.activePanel += 1;
          } else if (this.enableControls) {
            if (this.enableControls.listControls.includes(panel.id)) {
              if (!this.visibleControls.includes(panel.id)) {
                this.activePanel += 1;
              }
            }
          }
        }
      });
    },

    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        let errorList = [];
        this.formControls.forEach((field) => {
          if (
            this.$refs.form.errors[field.label] &&
            this.$refs.form.errors[field.label].length
          ) {
            errorList.push(field.label);
          }
        });
        this.$alert.warning("Required Mandatory Info", errorList);

        return;
      }

      this.$emit("save", this._fieldsModel);
    },

    isArrayField(fieldType) {
      return ["MULTIPLE_CHOICE", "FILE_UPLOAD", "MULTI_SELECT"].includes(
        fieldType
      );
    },

    isStatelessField(fieldType) {
      return ["LABEL", "DIVIDER", "PARAGRAPH"].includes(fieldType);
    },

    async processSave(value) {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        let errorList = [];
        this.formControls.forEach((field) => {
          if (
            this.$refs.form.errors[field.label] &&
            this.$refs.form.errors[field.label].length
          ) {
            errorList.push(field.label);
          }
        });
        this.$alert.warning("Required Mandatory Info", errorList);

        this.$emit("update:saveAction", "");
        this.$emit("update:workflowFormModel", {});
        return;
      }
      this.$emit("update:saveAction", value);
      this.$emit("update:workflowFormModel", this._fieldsModel);
    },

    checkDocument() {
      this.checkList.forEach((doc) => {
        if (doc.conditions) {
          if (doc.conditions.length) {
            doc.required = false;
            doc.conditions.forEach((condition) => {
              if (doc.groupLogic === "ANY" && doc.required) {
                return;
              }
              let fieldType =
                this.formFields.find((field) => field.value === condition.name)
                  ?.type || "";
              let conditionValue = condition.value;
              let fieldValue = this.fieldsModel[condition.name];
              if (fieldType === "NUMBER") {
                conditionValue = Number(conditionValue);
                fieldValue = Number(fieldValue);
              }
              switch (condition.logic) {
                case "IS_EQUALS_TO":
                  if (fieldValue === conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_NOT_EQUALS_TO":
                  if (fieldValue !== conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "CONTAINS":
                  if (fieldValue.indexOf(conditionValue) > -1) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_GREATER_THAN":
                  if (fieldValue > conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_GREATER_THAN_OR_EQUALS_TO":
                  if (fieldValue >= conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_LESSER_THAN":
                  if (fieldValue < conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_LESSER_THAN_OR_EQUALS_TO":
                  if (fieldValue <= conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
              }
            });
            if (doc.required || doc.visible) {
              doc.show = true;
            } else {
              doc.show = false;
            }
          } else {
            doc.show = true;
          }
        } else {
          doc.show = true;
        }
        return doc;
      });
    },

    documentValidation() {
      if (this._fieldsModel["74jNpB8IDZ3hVN_mGNy8K"]) {
        let attachDocs = this.checkList.filter((doc) => doc.attach);
        if (attachDocs.length) {
          attachDocs.forEach((doc) => {
            if (
              this._fieldsModel["74jNpB8IDZ3hVN_mGNy8K"].indexOf(doc.name) ===
              -1
            ) {
              this._fieldsModel["74jNpB8IDZ3hVN_mGNy8K"].push(doc.name);
            }
          });
        }
      }
    },

    setMandatoryControls(controls, settings, immediate, fieldId) {
      if (controls.length) {
        controls.forEach((item) => {
          for (let i = 0; i < this.panelCount; i++) {
            let index = this.panels[i].fields.findIndex((control) => {
              return control.id === item;
            });
            if (index > -1) {
              // let alreadyIndex = this.mandatoryControls.findIndex((element) => {
              //   return element.id === item;
              // });

              // if (alreadyIndex === -1) {
              if (
                this.panels[i].fields[index].settings.validation.fieldRule ===
                "OPTIONAL"
              ) {
                this.panels[i].fields[index].settings.validation.fieldRule =
                  "REQUIRED";
                this.mandatoryControls.push({
                  panelIndex: i,
                  fieldsIndex: index,
                  id: item,
                  fieldId: fieldId,
                });
              } else {
                this.mandatoryControls.push({
                  panelIndex: i,
                  fieldsIndex: index,
                  id: item,
                  value: "required",
                  fieldId: fieldId,
                });
              }
              // } else {
              //   // this.panels[i].fields[index].settings.validation.fieldRule =
              //   //   "OPTIONAL";
              // }
            }
          }
        });

        this.mandatoryControls.forEach((element) => {
          if (fieldId === element.fieldId) {
            let alreadyIndex = controls.findIndex((control) => {
              return element.id === control;
            });

            if (alreadyIndex === -1) {
              this.panels[element.panelIndex].fields[
                element.fieldsIndex
              ].settings.validation.fieldRule = "OPTIONAL";
            }
          }
        });
      } else {
        if (this.mandatoryControls.length) {
          this.mandatoryControls.forEach((control) => {
            if (!control.value && fieldId === control.fieldId) {
              this.panels[control.panelIndex].fields[
                control.fieldsIndex
              ].settings.validation.fieldRule = "OPTIONAL";
            }
          });
          this.mandatoryControls = [];
        }
        if (!immediate) {
          let fields = [];
          settings.forEach((set) => {
            fields.push(...set.controls);
          });
          fields.forEach((field) => {
            for (let i = 0; i < this.panelCount; i++) {
              let index = this.panels[i].fields.findIndex((control) => {
                return control.id === field;
              });

              if (index > -1) {
                if (
                  this.panels[i].fields[index].settings.validation.fieldRule ===
                  "REQUIRED"
                ) {
                  this.panels[i].fields[index].settings.validation.fieldRule =
                    "OPTIONAL";
                }
              }
            }
          });
        }
      }
    },

    uploadFiles(value, controls, fieldId, aiSettings) {
      this.$emit("uploadFiles", value, controls, fieldId, aiSettings);
    },

    deletedFiles(ids, files) {
      this.$emit("deletedFiles", ids, files);
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    setReadonlyControls(controls, settings, immediate, fieldId) {
      if (controls.length) {
        controls.forEach((item) => {
          for (let i = 0; i < this.panelCount; i++) {
            let index = this.panels[i].fields.findIndex((control) => {
              return control.id === item;
            });
            if (index > -1) {
              if (
                this.panels[i].fields[index].settings.general.visibility !==
                "READ_ONLY"
              ) {
                this.panels[i].fields[index].settings.general.visibility =
                  "READ_ONLY";
                this.fieldsModel[this.panels[i].fields[index].id] = "";

                this.readonlyControlsList.push({
                  panelIndex: i,
                  fieldsIndex: index,
                  id: item,
                  fieldId: fieldId,
                });
              } else {
                this.readonlyControlsList.push({
                  panelIndex: i,
                  fieldsIndex: index,
                  id: item,
                  value: "required",
                  fieldId: fieldId,
                });
              }
            }
          }
        });

        this.readonlyControlsList.forEach((element) => {
          if (fieldId === element.fieldId) {
            let alreadyIndex = controls.findIndex((control) => {
              return element.id === control;
            });

            if (alreadyIndex === -1) {
              this.panels[element.panelIndex].fields[
                element.fieldsIndex
              ].settings.general.visibility = "NORMAL";

              this.fieldsModel[
                this.panels[element.panelIndex].fields[element.fieldsIndex].id
              ] = "";
            }
          }
        });
      } else {
        if (this.readonlyControlsList.length) {
          this.readonlyControlsList.forEach((control) => {
            if (!control.value && fieldId === control.fieldId) {
              this.panels[control.panelIndex].fields[
                control.fieldsIndex
              ].settings.general.visibility = "NORMAL";

              this.fieldsModel[
                this.panels[control.panelIndex].fields[control.fieldsIndex].id
              ] = "";
            }
          });
          this.readonlyControlsList = [];
        }

        if (!immediate) {
          let fields = [];
          settings.forEach((set) => {
            fields.push(...set.controls);
          });
          fields.forEach((field) => {
            for (let i = 0; i < this.panelCount; i++) {
              let index = this.panels[i].fields.findIndex((control) => {
                return control.id === field;
              });

              if (index > -1) {
                if (
                  this.panels[i].fields[index].settings.general.visibility ===
                  "READ_ONLY"
                ) {
                  this.panels[i].fields[index].settings.general.visibility =
                    "NORMAL";
                  this.fieldsModel[this.panels[i].fields[index].id] = "";
                }
              }
            }
          });
        }
      }
    },

    async changePanelTab(value) {
      if (this.formSettings.general.layout === "TAB") {
        const isValid = await this.$refs.form.validate();
        console.log(isValid);

        let errorList = [];
        this.formControls.forEach((field) => {
          if (
            this.$refs.form.errors[field.label] &&
            this.$refs.form.errors[field.label].length
          ) {
            if (errorList.indexOf(field.label) === -1) {
              errorList.push(field.label);
            }
          }
        });
        console.log(errorList, "errorList");

        if (errorList.length) {
          this.$alert.warning("Required Mandatory Info", errorList);
        }

        if (!isValid) {
          return;
        } else {
          this.activePanel = value;
        }
      }
    },

    showQr(id) {
      this.$emit("showQr", id);
    },

    formSaveType(type, itemId) {
      this.$emit("formSaveType", type, itemId);
    },

    externalLoading(loading) {
      this.$emit("update:externalLoading", loading);
    },
  },
};
</script>

<style lang="scss" scoped>
#render-form {
  .actions {
    padding: 16px 0px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
